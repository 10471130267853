import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../Components/Typography";
import ImageIconButton from "../Components/ImageIconButton";
import '../CSS/BookingInfo.css';
import Images from '../Images/index';

const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  transition: theme.transitions.create("opacity"),
}));


const images = [
  {
    url: `${Images.Konsultation}`,
    title: "Konsultation",
    period: "20min",
    price: "100kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=115685&oid=6282848&tidval=20"
  },
  {
    url: `${Images.BookingAkupunktur2}`,
    title: "Kinesisk Akupunktur",
    period: "50min",
    price: "450kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=14556&oid=6553421&tidval=50"
  },
  {
    url: `${Images.Cuppling1}`,
    title: "Akupunktur & koppning",
    period: "60min",
    price: "600kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?minkalender=1&rid=6417&tjid=183916&tidval=60"
  },
  {
    url: `${Images.Self_Created_img}`,
    title: "Akupunktur & Massage",
    period: "60min",
    price: "600kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=183915&oid=6553424&tidval=60"
  },
  {
    url: `${Images.BodyMassage}`,
    title: "Kinesisk Kroppsmassage",
    period: "50min",
    price: "600kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=135538&oid=6553425&tidval=50"
  },
  {
    url: `${Images.NeckMassage}`,
    title: "Nack & Axelmassage",
    period: "30min",
    price: "350kr",
    width: "100%",
    href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=132282&oid=6282851&tidval=30"
  },
  // {
  //   url: `${Images.BackMassage}`,
  //   title: "Kinesisk Ryggmassage",
  //   period: "30min",
  //   price: "350kr",
  //   width: "100%",
  //   href:"https://www.timecenter.se/kistaakupunktur/boka/time/?rid=6417&tjid=132283&oid=6282850&tidval=30"
  // },


];

export default function BookingInfo() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h2" >
        Boka Tid Nu
      </Typography>
      <Typography className="paragraph" variant="h6" marked="center" align="center" component="h2" sx={{
        textTransform: "lowercase",
        fontWeight: "normal",
        mt:2
      }}>
        Kista Akupunktur klinik där man kan lugnt kan luta sig tillbaka och njuta av professionell service.
Här använder urgamla medicinska behandlingsmetoder för att frigöra och förebygga många
kroppsliga symtom. På kliniken erbjuder man allt från klassisk kinesisk akupunktur,
kroppsmassage, axelmassage, nackmassage, ansiktsmassage och ansiktsbehandlingar.
      </Typography>
      <Box sx={{ mt: 6, display: "flex", flexWrap: "wrap"}}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
              height: "20vh",
              marginBottom: 20,
              
            }}
          >
            <Link href={image.href} target="_blank">
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundSize: "cover",
                  backgroundPosition: "center 57%",
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <ImageBackdrop className="imageBackdrop" />

              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  color: "common.white",
                }}
              >
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className="imageTitle firstCharacter"
                  sx={{textTransform: 'lowercase'}}
                >
                  <span className="nameTag">{image.title}</span> <span className="priceTag">{image.period }</span> <span>{image.price}</span>
                  {/* <div className="imageMarked" /> */}
                </Typography>
          
              </Box>
            </Link>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
